// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-circle-pattern-js": () => import("./../../../src/pages/CirclePattern.js" /* webpackChunkName: "component---src-pages-circle-pattern-js" */),
  "component---src-pages-fractal-tree-js": () => import("./../../../src/pages/FractalTree.js" /* webpackChunkName: "component---src-pages-fractal-tree-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mandlebrot-js": () => import("./../../../src/pages/Mandlebrot.js" /* webpackChunkName: "component---src-pages-mandlebrot-js" */),
  "component---src-pages-narcissus-js": () => import("./../../../src/pages/Narcissus.js" /* webpackChunkName: "component---src-pages-narcissus-js" */),
  "component---src-pages-orbit-0-js": () => import("./../../../src/pages/Orbit0.js" /* webpackChunkName: "component---src-pages-orbit-0-js" */),
  "component---src-pages-orbit-1-js": () => import("./../../../src/pages/Orbit1.js" /* webpackChunkName: "component---src-pages-orbit-1-js" */),
  "component---src-pages-orbit-2-js": () => import("./../../../src/pages/Orbit2.js" /* webpackChunkName: "component---src-pages-orbit-2-js" */),
  "component---src-pages-shaders-0-js": () => import("./../../../src/pages/Shaders0.js" /* webpackChunkName: "component---src-pages-shaders-0-js" */),
  "component---src-pages-spheres-js": () => import("./../../../src/pages/Spheres.js" /* webpackChunkName: "component---src-pages-spheres-js" */)
}

